<template>
    <div class="work">
        <div class="flex-row flex-j-start flex-a-start">
            <!--            <Category @select="onCategorySelect"/>-->
            <!--            <banner/>-->
            <!--            <information/>-->
            <div style="width: 0.2rem"></div>
            <refresh-list ref="refreshList" @refresh="onPullDownRefresh" @load="onReachBottom">
                
                <div class="list" style="width: calc(16rem - 0.4rem);padding: 0 0.1rem">
                    <!--                    <condition-search :list="searchList" @active="onConditionActive"/>-->
                    <select-search :list="searchList" @active="onConditionActive"></select-search>
                    <work-cell v-for="(item, index) in list" :key="index" :item="item"></work-cell>
                </div>
                <empty-page v-if="!list||list.length===0" msg="暂无相关用工信息"/>
            </refresh-list>
        </div>
    </div>
</template>

<script>
// work
// import Category from "@/components/home/category";
import RefreshList from "@/components/refresh-list";
import EmptyPage from "@/components/empty-page";
import WorkCell from "@/components/work-cell";
import SelectSearch from "@/components/select-search";
// import ConditionSearch from "@/components/condition-search";
export default {
    name: "work",
    components: {
        SelectSearch,
        // ConditionSearch,
        WorkCell, EmptyPage, RefreshList,
        // Category
    },
    data() {
        return {
            searchList: [
                {
                    title: '岗位', field: 'postName', type: 'input',
                }, {
                    title: '所属镇街', field: 'areaName', list: []
                },
            ],
            list: [],
            pageSize: 15,
            pageIndex: 1,
            searchParams: {
                postName: "",
                // keyword: ""
            }
        }
    },
    mounted() {
        this.loadPageList();
        // 监听头部搜索框搜索事件
        window.$onIndexHeaderSearch = (keyword) => {
            this.searchParams.postName = keyword;
            // this.searchParams.keyword = keyword;
            // console.log(keyword)
            this.pageIndex = 1;
            this.list = [];
            this.loadPageList();
        }
        let areaNameList = $app.getStorageSync('areaList') || [];
        areaNameList = areaNameList.map(item => {
            item.name = item.AreaName;
            item.value = item.AreaName;
            return item;
        });
        this.searchList[1].list = areaNameList;
    },
    methods: {
        onCategorySelect(item) {
            console.log(item)
            this.searchParams.postName = item.name;
            this.pageIndex = 1;
            this.loadPageList();
        },
        onConditionActive(item) {
            console.log(item)
            this.searchParams[item.field] = item.name.replace(">", "").replace("面议", "");
            this.pageIndex = 1;
            this.loadPageList();
        },
        async loadPageList() {
            // let salary = this.searchList[2] || '~';
            const params = {
                areaName: this.searchParams.areaName || '',
                postName: this.searchParams.postName || "",
                // salaryMin: salary.split("~")[0] || 0,
                // salaryMax: salary.split("~")[1] || 0,
                // details: this.searchList[3] || '',
                // postType: 1,
                pageSize: this.pageSize,
                pageIndex: this.pageIndex
            };
            const res = await $app.httpGet({
                path: `/BaseFlexible/GetFlexibleList`,
                params: params,
            });
            this.$refs['refreshList'].stop();
            if (res.ok) {
                let list = this.list || [];
                let newList = res.data || [];
                
                newList = newList.map(item => {
                    let Requirements = item.Requirements || "";
                    let Welfare = item.Welfare || "";
                    // 汉字逗号改为英文逗号
                    Requirements = Requirements.replace(/，/g, ',').replace(/、/g, ",");
                    item.RequirementsList = Requirements.split(",");
                    
                    Welfare = Welfare.replace(/，/g, ",").replace(/、/g, ",");
                    item.WelfareList = Welfare.split(",");
                    
                    if (item.RequirementsList[0] == "") {
                        item.RequirementsList = [];
                    }
                    if (item.WelfareList[0] == "") {
                        item.WelfareList = [];
                    }
                    
                    item.upTimeText = $app.dateFormatChinese(item.BaseModifyTime);
                    return item;
                });
                
                if (this.pageIndex == 1) {
                    list = newList;
                } else {
                    list = list.concat(newList);
                    if (newList.length < this.pageSize) {
                        this.$refs['refreshList'].finish();
                    }
                }
                this.list = list;
            }
        },
        onPullDownRefresh() {
            this.pageIndex = 1;
            this.loadPageList();
        },
        onReachBottom() {
            this.pageIndex += 1;
            this.loadPageList();
        }
    }
}
</script>

<style scoped lang="less">
.work {
}
</style>
