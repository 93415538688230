<template>
    <div class="component work-cell flex-col flex-j-center flex-a-start tap box-sizing" @click="onClick">
            <!-- <image class="image"></image> -->
            <div class="flex-row flex-j-between flex-a-center" style="width: 100%;">
                <span class="title">{{item.PostName}}</span>
                <span v-if="item.SalaryMin <= 0" class="price">薪资面议</span>
                <span v-else class="price">{{item.SalaryMin}}</span>
            </div>
        
            <div class="flex-col flex-j-between flex-a-start" style="width: 100%;">
                <span class="des">工作地点：{{item.WorkAdd}}</span>
                <span class="des">发布人：{{item.LinkMan}}</span>
            </div>
        
            <div class="des">{{item.upTimeText}}</div>
    </div>
</template>

<script>
// work-cell
export default {
    name: "work-cell",
    props: ['item'],
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        onClick() {
            this.$router.push(`/work-detail?id=${this.item.Id}`)
        }
    }
}
</script>

<style scoped lang="less">
.work-cell {
    transition: all 0.3s ease;
    &:hover {
        box-shadow: 0 0.12rem 0.12rem 0 rgba(0, 0, 0, 0.1);
    }
}
.component {
    padding: .20rem;
    /* box-shadow: 0 0 8rem 0 rgba(0, 0, 0, 0.2); */
    border-radius: .12rem;
    background-color: #fff;
    margin-bottom: .20rem;
}
.component .image {
    width: 100%;
    min-height: 4.00rem;
    background-color: orange;
    border-radius: .12rem;
}

.component .title {
    font-size: .4rem;
    padding-bottom: .20rem;
}
.component .des {
    font-size: .32rem;
    color: #999999;
}
.price {
    color: #f1676d;
}
</style>
