<template>
    <div class="select-search flex-row flex-j-around flex-a-start">
        <template v-for="(item, index) in list || []">
            <input v-if="item.type==='input'" type="text" :key="index" :placeholder="`请输入${item.title}`"
                   @change="e => onInputChange(item,e)"/>
            <a-cascader
                v-else
                :field-names="postNameField"
                        :options="item.list"
                        :key="index"
                        :display-render="postNameDisplayRender"
                        expand-trigger="hover"
                        :placeholder="`请选择${item.title}`"
                        style="margin-right: 0.2rem"
                        @change="(value, a) => onPostNameChange(item.field, a)"
            />
        </template>
        
    </div>
</template>

<script>
// select-search
export default {
    name: "select-search",
    props: ['list'],
    data() {
        return {
            postNameField: {label: 'name', value: 'name', children: 'children'},
        }
    },
    mounted() {
    },
    methods: {
        onActive(item) {
            this.$emit('active', item);
        },
        postNameDisplayRender({labels}) {
            return labels[labels.length - 1];
        },
        onPostNameChange(field, a = []) {
            const item = a[a.length - 1] || {name: "", value: ""};
            item.field = field;
            this.$emit('active', item);
        },
        onInputChange(item, value) {
            item.name = item.value = value.target.value;
            this.$emit('active', item);
        }
    }
}
</script>

<style scoped lang="less">
.select-search {
    padding: 0.4rem;
    background-color: #fff;
    border-radius: 0.12rem;
    margin-bottom: 0.2rem;
}
input {
    width: 4.0rem;
    border: 1px solid gainsboro;
    border-radius: 0.12rem;
    padding: 0.08rem 0.12rem;
}
</style>
